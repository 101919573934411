/* Rtapp */
/* @charset "UTF-8"; */

/*colori
arancione: e8552e;
grigio sfondo: f5f5f5;
*/
/*font
	Montserrat 300 400 500 600 700 800 900
*/
/*variabili    		es.	color:var(--varA);*/
:root {
  --mainFont: Arial, Helvetica, sans-serif;
  --mainFontWeight: 400;
  --mainFontSize: 1em;
  --mainLineHeight: 1.15;
  --mainTextColor: #000000;
  --maxWidthArea: 1469px;
}
html * {
  box-sizing: border-box;
  outline: none;
}
body {
  font-size: 0.95em;
  color: #000;
  background-color: #efefef;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0;
  background-color: #f0f0f0;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img,
a {
  border: 0;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover,
a:hover * {
  transition: all 0.3s linear;
  transform: translateY(1) scale(1);
  -webkit-transform: translateY(1) scale(1);
}
a:hover {
  text-decoration: underline;
}
img {
  width: 100%;
  height: auto;
  float: left;
}
a,
img {
  outline: none;
}
p {
  padding-bottom: 2%;
  margin: 0;
}
b,
strong {
  font-weight: bold;
}
ul {
  margin-bottom: 2%;
}
ol li {
  display: inline-block;
  width: 100%;
}
input {
  height: 20px;
}
select {
  height: 30px;
}
label {
  vertical-align: middle;
}
input,
select {
  height: 22px;
}
input,
textarea,
select {
  border: none;
  line-height: 1;
  border: 1px solid #a8a8a8;
  border-radius: 0;
  padding: 0 5px;
  background-color: #fff;
  vertical-align: middle;
  color: #585858;
  font-family: var(--mainFont);
  font-weight: var(--mainFontWeight);
}
input:focus,
select:focus,
textarea:focus {
  color: #040404;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-color: #585858;
}
::-webkit-input-placeholder {
  color: #707070;
  font-family: var(--mainFont);
  font-weight: var(--mainFontWeight);
  font-size: 1em;
}
:-moz-placeholder {
  color: #000;
  opacity: 1;
  font-family: var(--mainFont);
  font-weight: var(--mainFontWeight);
  font-size: 1em;
}
::-moz-placeholder {
  color: #000;
  opacity: 1;
  font-family: var(--mainFont);
  font-weight: var(--mainFontWeight);
  font-size: 1em;
}
:-ms-input-placeholder {
  color: #000;
  font-family: var(--mainFont);
  font-weight: var(--mainFontWeight);
}
input:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
input:focus:-moz-placeholder {
  opacity: 0.4;
}
input:focus::-moz-placeholder {
  opacity: 0.4;
}
input:focus:-ms-input-placeholder {
  opacity: 0.4;
}
button {
  transition: all 0.3s linear;
  transform: translateY(1) scale(1);
  font-family: var(--mainFont);
  font-weight: var(--mainFontWeight);
}

body * {
  scrollbar-color: #818181 #f0f1f2;
  scrollbar-width: thin;
}
body *::-webkit-scrollbar {
  width: 3px;
}
body *::-webkit-scrollbar-track {
  background: #f0f1f2;
}
body *::-webkit-scrollbar-thumb {
  background: #818181;
}
body *::-webkit-scrollbar-thumb:hover {
  background: #818181;
}

/* preset */
.displayNo {
  display: none;
}
.row {
  width: 100%;
  float: left;
}
.rowMin {
  width: 100%;
  padding-left: 6%;
  padding-right: 6%;
  float: left;
}
.rowWidth {
  display: flex;
  width: 100%;
  position: relative;
}
.mobileOnly {
  display: none;
}
.alignCenter {
  text-align: center;
}
.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left;
}
.mBottom {
  margin-bottom: 25px;
}
/* generale ************************************************************************************************************************************************/

.wrap .topArea {
  width: 100%;
  float: left;
  padding: 20px 20px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.15);
}
.wrap .topArea p {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 13px;
  padding-bottom: 7px;
  line-height: 1em;
  font-weight: 400;
}
.wrap .topArea h1 {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  margin: 10px 0 0 0;
}
.wrap .topArea h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 17px;
  line-height: 1;
  font-weight: 700;
  margin: 7px 0 0 0;
}
.wrap .center {
  width: 100%;
  float: left;
  padding: 35px 90px 100px 90px;
}
.wrap .center ul.brands {
  width: 100%;
  float: none;
  max-width: 370px;
  margin: 0 auto;
  text-align: center;
}
.wrap .center ul.brands li {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 35px;
  margin-bottom: 20px;
}
.wrap .center ul.brands li:hover {
  background-color: #f09a04;
}
.wrap .center ul.brands li a {
  padding: 17px 15px;
  float: left;
  width: 100%;
}
.wrap .center ul.brands li h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 17px;
  line-height: 1em;
  margin: 0;
  font-weight: 300;
}
.wrap .center p.testo1 {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 14px;
  padding: 15px 0 15px 0;
}
.wrap .center ul.aggiungi-nuovo {
  width: 100%;
  float: none;
  max-width: 370px;
  margin: 0 auto;
  text-align: center;
}
.wrap .center ul.aggiungi-nuovo li {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 35px;
  margin-bottom: 20px;
padding: 17px 15px;	
}
.wrap .center ul.aggiungi-nuovo li:hover {
  background-color: #f09a04;
}
.wrap .center ul.aggiungi-nuovo li a {
 /* padding: 17px 15px;*/
  float: left;
  width: 100%;
}
.wrap .center ul.aggiungi-nuovo li h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 17px;
  line-height: 1em;
  margin: 0;
  font-weight: 300; /*background-image: url("../img/plus.svg"); background-repeat: no-repeat;background-position: right;*/
}
.wrap .center ul.aggiungi-nuovo li h2 span {
  float: right;
}
.wrap .center ul.aggiungi-nuovo li h2 span img {
  width: 10px;
  float: left;
}
.wrap .center ul.aggiungi-nuovo li h2 .fa {
  font-size: 0.8em;
  margin-left: 8px;
}
.wrap .center p.intro {
  width: 100%;
  float: left;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
  padding-bottom: 25px;
}
.wrap .center button.salva {
  background-color: #dadada;
  margin: 0 auto;
  display: block;
  border: 0px;
  border-radius: 35px;
  padding: 17px 15px;
  width: 370px;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
}
.wrap .center ul.brands-list {
  width: 100%;
  float: none;
  max-width: 370px;
  margin: 0 auto;
  text-align: center;
}
.wrap .center ul.brands-list li {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 35px;
  margin-bottom: 20px;
  padding: 17px 15px;
}
.wrap .center ul.brands-list li h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 17px;
  line-height: 1em;
  margin: 0;
  font-weight: 300;
}
.wrap .center .cont-help{text-align: center}
.wrap .center .cont-help h2{width: 100%;float: left;font-size: 14px;line-height: 1.5;margin: 0 0 15px 0;text-align: left}
.wrap .center .cont-help p{width: 100%;float: left;line-height: 1.5;font-size: 14px;padding-bottom: 40px;text-align: left}
.wrap .center .cont-help img{max-width: 370px;margin: 0 auto;text-align: center;border: 1px solid #b1aeae;margin-bottom: 40px;float: none;width: 100%;}
.wrap .center .cont-help .cont-img{width: 100%;float: left;text-align: center}


.row.cont-servizi {
  display: -webkit-box;
  display: flex;
  margin-bottom: 40px;
}
.servizi-brand .wrap .center h2 {
  width: 100%;
  float: left;
  font-weight: 200;
  font-size: 27px;
  text-align: center;
  margin: 0px;
  margin-bottom: 10px;
}
.row.cont-servizi .col {
  -webkit-box-flex: 1;
  flex: 1;
}

/* Accordion styles */
.row.cont-servizi .tabs {
  overflow: hidden;
}
.row.cont-servizi .tab {
  width: 100%;
  color: white;
  overflow: hidden;
  border-bottom: 1px solid #000;
}
.row.cont-servizi .tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.row.cont-servizi .tab .cont-input {
  width: 100%;
  float: left;
  position: relative;
}

.cont-servizi .cont-input label{width: 100%;float: left;padding: 0rem 0 .7rem 0;text-align: center}


.row.cont-servizi .tab .cont-input a.icona {
  position: absolute;
  right: 20px;
  top: 16px;
  z-index: 99;
}
.row.cont-servizi .tab .cont-input input.input-bianco {
  position: relative;
  width: 100%;
  float: left;
  background-color: #fff;
  opacity: inherit;
  border-radius: 35px;
  margin-bottom: 20px;
  padding: 17px 15px;
  border: 0px;
  font-size: 17px;
  line-height: 1em;
  font-weight: 300;
  height: 51px;
  z-index: 9;
  text-align: center;
}
.row.cont-servizi .tab .cont-input input.input-bianco::-webkit-input-placeholder {
  font-size: 17px;
  line-height: 1em;
  margin: 0;
  font-weight: 300;
  color: #000;
  font-family: 'Montserrat', sans-serif;
}
.row.cont-servizi .tab .cont-input input.input-bianco:-ms-input-placeholder {
  font-size: 17px;
  line-height: 1em;
  margin: 0;
  font-weight: 300;
  color: #000;
  font-family: 'Montserrat', sans-serif;
}
.row.cont-servizi .tab .cont-input input.input-bianco::placeholder {
  font-size: 17px;
  line-height: 1em;
  margin: 0;
  font-weight: 300;
  color: #000;
  font-family: 'Montserrat', sans-serif;
}

.row.cont-servizi .tab-label {
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 25px 0;
  font-weight: bold;
  cursor: pointer;
  color: #000;
  font-size: 17px;
  width: 100%;
  display: table;
  line-height: 1.2;
}
.row.cont-servizi.orari .tab {
  max-width: 370px;
  margin: 0 auto;
}
.row.cont-servizi.orari .tab-label {
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 25px 0;
  font-weight: bold;
  cursor: pointer;
  color: #000;
  font-size: 17px;
  width: 100%;
  display: table;
  line-height: 1.2;
  text-align: center;
  position: relative;
}
.row.cont-servizi .tab-label:hover {
}
.row.cont-servizi .tab-label::after {
  content: '\276F';
  width: 1em;
  height: 1em;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  padding-left: 20px;
  font-size: 0.6em;
  display: table-cell;
  vertical-align: middle;
  justify-content: space-between;
}
.row.cont-servizi.orari .tab-label::after {
  display: none;
}
.row.cont-servizi.orari .tab-label a.trash {
  position: absolute;
  right: 0px;
  z-index: 999999;
}
.row.cont-servizi.orari .tab-label .fa {
  font-size: 18px;
}
.row.cont-servizi .tab-content {
  max-height: 0;
  color: #2c3e50;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  float: left;
}
.row.cont-servizi .tab-close {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.row.cont-servizi .tab-close:hover {
}
.row.cont-servizi.orari .tab-content {
  width: 100%;
  float: left;
}
.row.cont-servizi.orari .tab-content .info {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.row.cont-servizi.orari .tab-content .info span {
  float: left;
  width: 100%;
  margin-right: 0;
  text-align: center;
}
.row.cont-servizi.orari .tab-content .info span img {
  width: 17px;
  float: none;
}
.row.cont-servizi.orari .tab-content .info p {
  width: 100%;
  float: left;
  font-size: 13px;
  text-align: center;
  line-height: 1.3;
  padding-bottom: 2px;
}
.row.cont-servizi.orari .tab-content button.stato {
  width: 100%;
  max-width: 370px;
  margin: 0 auto 45px auto;
  text-align: center;
  float: none;
  display: table;
  background-color: #dadada;
  border-radius: 35px;
  margin-bottom: 20px;
  padding: 17px 15px;
  border: 0px;
  font-size: 17px;
  line-height: 1em;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 45px;
}
.row.cont-servizi.orari .tab-content button.open {
  background-color: #dadada;
}
.row.cont-servizi.orari .tab-content button.closed {
  background-color: #f09a04;
  color: #fff;
  font-weight: 400;
}

.row.cont-servizi.orari .nuovo button.stato {
  width: 100%;
  max-width: 370px;
  margin: 0 auto 45px auto;
  text-align: center;
  float: none;
  display: table;
  background-color: #dadada;
  border-radius: 35px;
  margin-bottom: 20px;
  padding: 17px 15px;
  border: 0px;
  font-size: 17px;
  line-height: 1em;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 45px;
}
.row.cont-servizi.orari .nuovo button.open {
  background-color: #dadada;
}
.row.cont-servizi.orari .nuovo button.closed {
  background-color: #000;
  color: #000;
  font-weight: 400;
}
.row.cont-servizi.orari .nuovo .info {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.row.cont-servizi.orari .nuovo .info span {
  float: left;
  width: 100%;
  margin-right: 0;
  text-align: center;
}
.row.cont-servizi.orari .nuovo .info span img {
  width: 17px;
  float: none;
}
.row.cont-servizi.orari .nuovo .info p {
  width: 100%;
  float: left;
  font-size: 13px;
  text-align: center;
  line-height: 1.3;
  padding-bottom: 2px;
  color: #000;
}

.row.cont-servizi input:checked + .tab-label {
  /*background: #1a252f*/
}
.row.cont-servizi input:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  vertical-align: baseline;
  padding: 0 20px 20px 0;
}
.row.cont-servizi input:checked ~ .tab-content {
  max-height: none;
}
.row.cont-servizi ul.servizi {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}
.row.cont-servizi ul.servizi li {
  float: left;
  background-color: #fff;
  width: 22%;
  border-radius: 25px;
  margin-bottom: 30px;
  height: 190px;
  padding: 25px 10px 10px 10px;
  text-align: center;
  margin: 0 1.5% 16px 1.5%;
}
.row.cont-servizi ul.servizi li p {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 15px;
  line-height: 1.2;
}
.row.cont-servizi ul.servizi li img {
  width: 75%;
  margin: 0 auto;
  float: none;
  max-width: 100px;
}
.row.cont-servizi ul.servizi.aggiungi li.not-selected {
  background-color: #fff;
}
.row.cont-servizi ul.servizi.aggiungi li.selected {
  background-color: #f09a04;
}
.row.cont-servizi ul.servizi.aggiungi li:active {
  /*background-color: #000*/
}
.row.cont-servizi ul.servizi.aggiungi li:hover {
  /*background-color:#f09a04*/
}
.row.cont-servizi ul.servizi.aggiungi li:focus {
  /*background-color:#f09a04*/
}
.row.cont-servizi ul.servizi.size2 li {
  width: 47%;
  margin: 0 1.5% 16px 1.5%;
}
.row.cont-servizi ul.servizi.size3 li {
  width: 100%;
  margin: 0 1.5% 16px 1.5%;
}
.row.cont-servizi ul.servizi.size3 li p {
  font-size: 21px;
}
.row.cont-servizi ul.servizi.size3 li img {
  max-width: 115px;
}

.wrap .center ul.orari-list {
  width: 100%;
  float: none;
  max-width: 370px;
  margin: 0 auto 25px auto;
  text-align: center;
  display: table;
}
.wrap .center ul.orari-list li {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 35px;
  margin-bottom: 20px;
  padding: 17px 15px;
}
.wrap .center ul.orari-list li h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 17px;
  line-height: 1em;
  margin: 0;
  font-weight: 300;
  position: relative;
}
.wrap .center ul.orari-list li h2 .fa {
  font-size: 1em;
  float: right;
  position: absolute;
  right: 10px;
  top: 0;
}

.orari-speciali .wrap .center button.new {
  width: 100%;
  max-width: 370px;
  margin: 0 auto 20px auto;
  text-align: center;
  float: none;
  display: table;
  background-color: #dadada;
  border-radius: 35px;
  margin-bottom: 0px;
  padding: 17px 15px;
  border: 0px;
  font-size: 17px;
  line-height: 1em;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
}

.orari-speciali .wrap .center input.input-bianco{position: relative;background-color: #fff;opacity: inherit;border-radius: 35px;padding: 17px 15px;
    border: 0px;font-size: 17px;line-height: 1em;height: 51px;z-index: 9;text-align: center;margin: 0 auto;display: block;width: 370px;font-family: "Montserrat",sans-serif;font-weight: 400;color: #000;margin-bottom: 20px;}
.orari-speciali .cont-servizi.orari.orari-speciali .tab-content{max-width: 370px;margin: 0 auto;display: block;max-height: none;float: none;}
.orari-speciali .cont-servizi.orari.orari-speciali .tab-content .cont-input {width: 100%;float: left;position: relative}
.orari-speciali .cont-servizi.orari.orari-speciali .tab-content .cont-input input.input-bianco {position: relative;width: 100%;float: left;background-color: #fff;opacity: inherit;border-radius: 35px;margin-bottom: 20px;padding: 17px 15px;border: 0;font-size: 17px;line-height: 1em;font-weight: 300; height: 51px;z-index: 9;text-align: center}
.orari-speciali .cont-servizi.orari.orari-speciali .tab-content .cont-input button.stato{width: 100%;max-width: 370px;margin: 0 auto 45px auto;text-align: center;float: none;display: table;
    background-color: #dadada;border-radius: 35px;padding: 17px 15px;border: 0px;font-size: 17px;line-height: 1em;font-weight: 300;font-family: 'Montserrat',sans-serif;margin-bottom: 45px;}
.orari-speciali .cont-servizi.orari.orari-speciali .tab-content .cont-input button.open{background-color:#dadada}
.orari-speciali .cont-servizi.orari.orari-speciali .tab-content .cont-input button.closed{background-color: #000;color: #fff;font-weight:400;}
.cont-servizi.orari .info {width: 100%;float: left;margin-bottom: 10px}
.cont-servizi.orari .info span {float: left;width: 100%;margin-right: 0;text-align: center}
.cont-servizi.orari .info span  img {width: 17px;float: none}
.cont-servizi.orari .info p {width: 100%;float: left;font-size: 13px;text-align: center;line-height: 1.3;padding-bottom: 2px;color: #000}


/*Messaggi salvataggio*/

.Toastify__toast.Toastify__toast--success.custom_rt {opacity: 1!important; padding: 8px 8px 8px 15px;background-color: #f09a04;}

.Toastify__toast.Toastify__toast--success.custom_rt .Toastify__close-button  {color: #000}

.Toastify__toast.Toastify__toast--success.custom_rt .Toastify__toast-body  {color: #000;font-family: "Montserrat",sans-serif;font-weight: 400}



@media only screen and (min-width: 0) and (max-width: 1200px) {
}
@media only screen and (min-width: 0) and (max-width: 1024px) {
}

@media only screen and (min-width: 0) and (max-width: 768px) {
  .wrap .center {
    width: 100%;
    float: left;
    padding: 55px 90px 100px 90px;
  }
  .orari .wrap .center {
    padding: 35px 90px 100px 90px;
  }
}

@media only screen and (min-width: 0) and (max-width: 767px) {
}
@media only screen and (min-width: 0) and (max-width: 414px) {
  /*smaller phone*/
  .wrap .center {
    width: 100%;
    float: left;
    padding: 30px 40px 100px 40px;
  }
  .orari .wrap .center {
    padding: 30px 40px 100px 40px;
  }
  .row.cont-servizi ul.servizi li {
    width: 47%;
  }
  .wrap .center button.salva {
    width: 100%;
  }
  .wrap .center ul.brands {
    width: 100%;
    float: left;
    margin: 0 0 0 0;
  }
  .wrap .center ul.aggiungi {
    width: 100%;
    float: left;
  }
  .row.cont-servizi ul.servizi {
    justify-content: space-between;
  }
  .row.cont-servizi ul.servizi li {
    width: 47%;
    margin: 0 0 30px 0;
  }
  .row.cont-servizi ul.servizi.size2 li {
    width: 100%;
  }
  .row.cont-servizi ul.servizi.size3 li {
    width: 100%;
    height: 260px;
    padding: 35px 10px 10px 10px;
  }
  .row.cont-servizi ul.servizi.size3 li img {
    max-width: 130px;
  }
	.orari-speciali .wrap .center input.input-bianco{width:100%}
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8fZwnCo.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z-PZwnCo.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8_ZwnCo.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8vZwnCo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z_PZw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8fZwnCo.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz-PZwnCo.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8_ZwnCo.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8vZwnCo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz_PZw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxC7mw9c.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRzS7mw9c.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxi7mw9c.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxy7mw9c.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* input type time */
/* input[type='date'],
input[type='time'] {
  -webkit-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
} */

input[type='time'] {
  -webkit-appearance: none;
  -webkit-min-logical-width: calc(100% - 16px);
  display: block;
}
